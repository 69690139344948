// Here you can add other styles
.app-header {
  background: #2a53ae;
  border: 1px solid #2a53ae;

  .navbar-toggler {
    background: #ffffff;
  }
}

.sidebar {
  background-color: #ECF0F3;

  .sidebar-minimizer {
    background-color: #ECF0F3;

    :hover {
      background-color: #ECF0F3;
    }
  }

  .nav-link {
    color: #000000;
  }

  .nav-link.active, .nav-link:hover  {
    background-color: #2a53ae;
  }
}
